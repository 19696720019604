import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "summary-delta-between-vexchange-v2-and-uniswap-v2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#summary-delta-between-vexchange-v2-and-uniswap-v2",
        "aria-label": "summary delta between vexchange v2 and uniswap v2 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Summary delta between Vexchange V2 and Uniswap V2`}</h1>
    <p>{`This section outlines the key differences between Vexchange V2 and Uniswap V2, and walks through each of the areas of difference in the smart contract implementation.`}</p>
    <h2 {...{
      "id": "variable-fees",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#variable-fees",
        "aria-label": "variable fees permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Variable Fees`}</h2>
    <p>{`Vexchange V2 introduced the ability to adjust swap fees up and down through governance.`}</p>
    <p>{`This feature was present in V1 but was underutilized, as no active governance mechanism was developed. In V2, we plan to deploy and encourage active governance over the platform to ensure Vexchange meets its goals of providing the best VIP180 swap experience possible.`}</p>
    <p>{`Uniswap V3 has also moved towards multiple fee levels, which reinforces the value variable swap & platform fees will add to the Vexchange ecosystem.`}</p>
    <p>{`The fee implementation is 100% consistent with the original protocol fee equations outlined in the original `}<a parentName="p" {...{
        "href": "https://uniswap.org/whitepaper.pdf"
      }}>{`Uniswap whitepaper`}</a>{` (see equation 6), with our contract efficiently implementing a derivation of the equation maintaining the fee variable Phi.`}</p>
    <p>{`Swap fees can be set to a value in a range from 0.05% to 2%, and platform fee can be up to 50%.`}</p>
    <p>{`Vexchange V2 will launch with the same fee configuration as V1 to aid in a seamless migration. However, we expect that the community will quickly want to move towards refining the fee configuration towards something more efficient for each asset being traded.`}</p>
    <p>{`Default fee parameters for pair creation and setting pair-specific parameters is managed by the `}<a parentName="p" {...{
        "href": "#Contract:%20VexchangeV2Factory"
      }}>{`VexchangeV2Factory`}</a>{`, however specific fees and the fee calculations are maintained on the `}<a parentName="p" {...{
        "href": "#Contract:%20VexchangeV2Pair"
      }}>{`VexchangeV2Pair`}</a>{` contract itself.`}</p>
    <p>{`See the VexchangeV2Pair method `}<a parentName="p" {...{
        "href": "#calcFee%20method"
      }}>{`_calcFee()`}</a>{` for more details on the platform fee calculation.`}</p>
    <h2 {...{
      "id": "recoverer-mechanism",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#recoverer-mechanism",
        "aria-label": "recoverer mechanism permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Recoverer Mechanism`}</h2>
    <p>{`Vexchange V2 introduces the concept of token recovery, for tokens that have been invalidly transferred into a given Vexchange V2 pair.`}</p>
    <p>{`The VexchangeV2Pair supports transferral of token balances that may be held by the pair, so long as they are not for tokens of one of the pairs swapping tokens. The mechanism does not allow transfer to the zero address.`}</p>
    <p>{`This mechanism is supported by only a single `}<em parentName="p">{`recovery address`}</em>{`, managed by Vexchange governance. In operation, default recoverer address is defined on the VexchangeV2Factory, with the actual address to be used defined on a given VexchangeV2Pair.`}</p>
    <p>{`See accessors on the VexchangeV2Factory and VexchangeV2Pair, as well as the recoverTokens method on the VexchangeV2Pair for more information.`}</p>
    <p>{`See the VexchangeV2Pair method `}<a parentName="p" {...{
        "href": "#recoverToken%20method"
      }}>{`recoverToken`}</a>{` for more details on the recover operation.`}</p>
    <h2 {...{
      "id": "contract-vexchangev2factory",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#contract-vexchangev2factory",
        "aria-label": "contract vexchangev2factory permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Contract: VexchangeV2Factory`}</h2>
    <p>{`The changes in the factory contract primarily cover the introduction of the foundational elements of variable fee support.  This includes state management and updating support for the fee variables, the recoverer mechanism and changes to the Factory construction and Pair initialization.`}</p>
    <h3 {...{
      "id": "ownable",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#ownable",
        "aria-label": "ownable permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Ownable`}</h3>
    <p>{`The VexchangeV2Factory contract applies the `}<em parentName="p">{`Ownable`}</em>{` contract pattern, implemented through inheritance of functionality defined in the contract Ownable, defined in contracts/libraries.`}</p>
    <p>{`This implementation of the Ownable pattern is minimalist, supporting only tracking the current owner, transfer of ownership and defining the `}<em parentName="p">{`onlyOwner`}</em>{` modifier; which applies the access restriction to the various ExchangeV2Factory methods.`}</p>
    <h3 {...{
      "id": "constants",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#constants",
        "aria-label": "constants permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Constants`}</h3>
    <p>{`Constants have been defined for defining the range of both the swap fee and platform fee. This constants are used as requirements around valid values of the variable fees during their setting and initialization.`}</p>
    <h3 {...{
      "id": "variable-fee-state",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#variable-fee-state",
        "aria-label": "variable fee state permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Variable Fee state`}</h3>
    <p>{`Another necessary addition to the factory was a set of variables to maintain the variable fee current state, which is held in variables prefixed as “default…”; which means the default values the Factory holds to initialize a new Pair. During the initialization itself, the Pair may be initialized with fee parameters as required.`}</p>
    <h3 {...{
      "id": "contract-events",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#contract-events",
        "aria-label": "contract events permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Contract Events`}</h3>
    <p>{`All aspects of the contract operation are covered by the emitted contract events, and so the Uniswap V2 events have been extended with additional events to cover fee variable state changes and changes to the recoverer address.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`event PairCreated(address indexed token0, address indexed token1, address pair, uint, uint swapFee, uint platformFee);
event PlatformFeeToChanged(address oldFeeTo, address newFeeTo);
event DefaultSwapFeeChanged(uint oldDefaultSwapFee, uint newDefaultSwapFee);
event DefaultPlatformFeeChanged(uint oldDefaultPlatformFee, uint newDefaultPlatformFee);
event DefaultRecovererChanged(address oldDefaultRecoverer, address newDefaultRecoverer);
`}</code></pre>
    <h3 {...{
      "id": "factory-construction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#factory-construction",
        "aria-label": "factory construction permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Factory Construction`}</h3>
    <p>{`The VexchangeV2Factory constructor has a new signature, but a functionally simple change. It adds parameters for initializing the default fee parameters, and the default recoverer account.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`constructor( uint _defaultSwapFee, uint _defaultPlatformFee, address _defaultRecoverer ) public
`}</code></pre>
    <h3 {...{
      "id": "pair-construction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#pair-construction",
        "aria-label": "pair construction permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Pair Construction`}</h3>
    <p>{`The createPair method, responsibility for spawning the swap contracts for specific pairs, has been augmented to initialize the pair-contracts with the Factory default fee parameters.`}</p>
    <p>{`For compatibility and minimization of change, the method’s interface remains unchanged as can be seen below, though the values set for swap-fee and platform-fee are emitted in the associated event for auditability.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function createPair(address tokenA, address tokenB) external returns (address pair)

emit PairCreated(token0, token1, pair, allPairs.length, defaultSwapFee, defaultPlatformFee);
`}</code></pre>
    <p>{`The Pair supports public methods for future maintenance of the fee parameters.`}</p>
    <h3 {...{
      "id": "new-accessor-methods",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#new-accessor-methods",
        "aria-label": "new accessor methods permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`New Accessor Methods`}</h3>
    <p>{`Several additional methods have been added for setting or retrieving Factory state, they are:`}</p>
    <h4 {...{
      "id": "getpairinithash",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#getpairinithash",
        "aria-label": "getpairinithash permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`getPairInitHash()`}</h4>
    <p>{`A convenience method to access the Pair contract initialization hash in dependent code.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function getPairInitHash() public pure returns(bytes32)
`}</code></pre>
    <h4 {...{
      "id": "setplatformfeeto",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#setplatformfeeto",
        "aria-label": "setplatformfeeto permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`setPlatformFeeTo()`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function setPlatformFeeTo(address _platformFeeTo) external onlyOwner
`}</code></pre>
    <h4 {...{
      "id": "setdefaultswapfee",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#setdefaultswapfee",
        "aria-label": "setdefaultswapfee permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`setDefaultSwapFee()`}</h4>
    <p>{`Default swap-fee is used when creating new Pairs.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function setDefaultSwapFee(uint _swapFee) external onlyOwner
`}</code></pre>
    <h4 {...{
      "id": "defaultplatformfeeon",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#defaultplatformfeeon",
        "aria-label": "defaultplatformfeeon permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`defaultPlatformFeeOn()`}</h4>
    <p>{`A utility method analogous to the Uniswap feeOn determination. In Vexchange, the fee is defined as “on” when the platform-fee is greater than 0.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function defaultPlatformFeeOn() external view returns (bool _platformFeeOn)
`}</code></pre>
    <h4 {...{
      "id": "setdefaultplatformfee",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#setdefaultplatformfee",
        "aria-label": "setdefaultplatformfee permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`setDefaultPlatformFee()`}</h4>
    <p>{`Default platform-fee is used when creating new Pairs.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function setDefaultPlatformFee(uint _platformFee) external onlyOwner
`}</code></pre>
    <h4 {...{
      "id": "setdefaultrecoverer",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#setdefaultrecoverer",
        "aria-label": "setdefaultrecoverer permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`setDefaultRecoverer()`}</h4>
    <p>{`Default recoverer is used when creating new Pairs.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function setDefaultRecoverer(address _recoverer) external onlyOwner
`}</code></pre>
    <h4 {...{
      "id": "setswapfeeforpair",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#setswapfeeforpair",
        "aria-label": "setswapfeeforpair permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`setSwapFeeForPair()`}</h4>
    <p>{`Sets the swap-fee on a specific pair contract.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function setSwapFeeForPair(address _pair, uint _swapFee) external onlyOwner
`}</code></pre>
    <h4 {...{
      "id": "setplatformfeeforpair",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#setplatformfeeforpair",
        "aria-label": "setplatformfeeforpair permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`setPlatformFeeForPair()`}</h4>
    <p>{`Sets the platform-fee on a specific pair contract.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function setPlatformFeeForPair(address _pair, uint _platformFee) external onlyOwner
`}</code></pre>
    <h4 {...{
      "id": "setrecovererforpair",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#setrecovererforpair",
        "aria-label": "setrecovererforpair permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`setRecovererForPair()`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function setRecovererForPair(address _pair, address _recoverer) external onlyOwner
`}</code></pre>
    <p>{`Sets the recovery address on a specific pair contract.`}</p>
    <h2 {...{
      "id": "contract-vexchangev2pair",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#contract-vexchangev2pair",
        "aria-label": "contract vexchangev2pair permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Contract: VexchangeV2Pair`}</h2>
    <p>{`The ExchangeV2Pair is the most significantly modified contract from the original Uniswap V2 implementation.`}</p>
    <h3 {...{
      "id": "new-constants-and-contract-state",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#new-constants-and-contract-state",
        "aria-label": "new constants and contract state permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`New constants and contract state`}</h3>
    <p>{`Constants have been added for the variable fee calculations - for range constraints for the fee values, as well as accuracy multipliers. These accuracy constants are used to support accurate calculation of the platform fee.`}</p>
    <p>{`Additional state variables were added to support the swap-fee and platform-fee, and the recoverer address.`}</p>
    <h3 {...{
      "id": "new-events",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#new-events",
        "aria-label": "new events permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`New Events`}</h3>
    <p>{`Events were added to VexchangeV2Pair to notify of all transactions occurring through the contract.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`event SwapFeeChanged(uint oldSwapFee, uint newSwapFee);
event PlatformFeeChanged(uint oldPlatformFee, uint newPlatformFee);
event RecovererChanged(address oldRecoverer, address newRecoverer);
`}</code></pre>
    <h3 {...{
      "id": "calcfee-method",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#calcfee-method",
        "aria-label": "calcfee method permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`calcFee method`}</h3>
    <p>{`The _`}<em parentName="p">{`calcFee`}</em>{` method calculates the appropriate platform fee in terms of tokens that will be minted, based on the growth in sqrt(k), the amount of liquidity in the pool, and the set variable fee in basis points.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function _calcFee(uint _sqrtNewK, uint _sqrtOldK, uint _platformFee, uint _circulatingShares) internal pure returns (uint _sharesToIssue)
`}</code></pre>
    <p>{`This function implements the equation defined in the Uniswap V2 whitepaper for calculating platform fees, on which their fee calculation implementation is based. This is a refactored form of equation 6, on page 5 of the Uniswap whitepaper; see `}<a parentName="p" {...{
        "href": "https://uniswap.org/whitepaper.pdf"
      }}>{`https://uniswap.org/whitepaper.pdf`}</a>{` for further details.`}</p>
    <p>{`The specific difference between the Uniswap V2 implementation and this fee calculation is the fee variable, which remains a variable with range 0-50% here, but is fixed at (1/6)% in Uniswap V2.`}</p>
    <p>{`The mathematical equation:
If ‘Fee’ is the platform fee, and the previous and new values of the square-root of the invariant k, are K1 and K2 respectively; this equation, in the form coded here can be expressed as:`}</p>
    <p>{`  _sharesToIssue = totalSupply `}<em parentName="p">{` Fee `}</em>{` (1 - K1/K2) / ( 1 - Fee * (1 - K1/K2) )`}</p>
    <p>{`A reader of the whitepaper will note that this equation is not a literally the same as equation (6), however with some straight-forward algebraic manipulation they can be shown to be mathematically equivalent.`}</p>
    <h3 {...{
      "id": "recovertoken-method",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#recovertoken-method",
        "aria-label": "recovertoken method permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`recoverToken method`}</h3>
    <p>{`Recover the specified token from the VexchangeV2Pair, transferring it to the defined recoverer address.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function recoverToken(address token) external
`}</code></pre>
    <p>{`The requirements of this method guarantee that neither of the pairs swapping tokens can be transferred by this method, or that the balance can be sent to the zero address.`}</p>
    <h3 {...{
      "id": "new-accessor-methods-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#new-accessor-methods-1",
        "aria-label": "new accessor methods 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`New Accessor methods`}</h3>
    <p>{`Several additional methods have been added for setting or retrieving Pair state, they are:`}</p>
    <h4 {...{
      "id": "platformfeeon",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#platformfeeon",
        "aria-label": "platformfeeon permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`platformFeeOn()`}</h4>
    <p>{`Convenience function, returning whether or not the platform fee is engaged; true if the platform-fee is greater than zero.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function platformFeeOn() external view returns (bool _platformFeeOn)
`}</code></pre>
    <h4 {...{
      "id": "setswapfee",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#setswapfee",
        "aria-label": "setswapfee permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`setSwapFee()`}</h4>
    <p>{`Allows the swap-fee to be set on this pair; this is callable only by the parent VexchangeV2Factory.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function setSwapFee(uint _swapFee) external onlyFactory
`}</code></pre>
    <h4 {...{
      "id": "setplatformfee",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#setplatformfee",
        "aria-label": "setplatformfee permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`setPlatformFee()`}</h4>
    <p>{`Allows the platform-fee to be set on this pair; this is callable only by the parent VexchangeV2Factory.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function setPlatformFee(uint _platformFee) external onlyFactory
`}</code></pre>
    <h4 {...{
      "id": "setrecoverer",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#setrecoverer",
        "aria-label": "setrecoverer permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`setRecoverer()`}</h4>
    <p>{`Allows the recoverer address to be set on this pair; this is callable only by the parent VexchangeV2Factory.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function setRecoverer(address _recoverer) external onlyFactory
`}</code></pre>
    <h2 {...{
      "id": "contract-vexchangev2erc20",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#contract-vexchangev2erc20",
        "aria-label": "contract vexchangev2erc20 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Contract: VExchangeV2ERC20`}</h2>
    <p>{`This contract was cosmeticaly changed only, with some constants modified, such as the name used in the EIP-712 domain separator encoding (to ‘Vexchange’).`}</p>
    <p>{`Also note that since this contract references the chainid, which is implicitly defined in the EVM, aside from the name change to Vexchange V2, the ABI encoding of the DOMAIN_SEPARATOR would not be byte equivalent to Uniswap V2.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      